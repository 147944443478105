var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"body-class":"p-0","header-class":"border-0"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h3',{staticClass:"mb-0"},[_vm._v("Light table")])]},proxy:true}])},[_c('el-table',{staticClass:"table-responsive table-flush",attrs:{"header-row-class-name":"thead-light","data":_vm.projects.slice(0, _vm.itemLimit)}},[_c('el-table-column',{attrs:{"label":"Project","min-width":"310px","prop":"name","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b-media',{staticClass:"align-items-center",attrs:{"no-body":""}},[_c('a',{staticClass:"avatar rounded-circle mr-3",attrs:{"href":"#"}},[_c('b-img',{attrs:{"alt":"Image placeholder","src":row.img}})],1),_c('b-media-body',[_c('span',{staticClass:"font-weight-600 name mb-0 text-sm"},[_vm._v(_vm._s(row.title))])])],1)]}}])}),_c('el-table-column',{attrs:{"label":"Budget","prop":"budget","min-width":"140px","sortable":""}}),_c('el-table-column',{attrs:{"label":"Status","min-width":"170px","prop":"status","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('badge',{staticClass:"badge-dot mr-4",attrs:{"type":""}},[_c('i',{class:("bg-" + (row.statusType))}),_c('span',{staticClass:"status"},[_vm._v(_vm._s(row.status))])])]}}])}),_c('el-table-column',{attrs:{"label":"Users","min-width":"190px"}},[_c('div',{staticClass:"avatar-group"},[_c('a',{staticClass:"avatar avatar-sm rounded-circle",attrs:{"href":"#","data-toggle":"tooltip","data-original-title":"Ryan Tompson"}},[_c('img',{attrs:{"alt":"Image placeholder","src":"img/theme/team-1.jpg"}})]),_c('a',{staticClass:"avatar avatar-sm rounded-circle",attrs:{"href":"#","data-toggle":"tooltip","data-original-title":"Romina Hadid"}},[_c('img',{attrs:{"alt":"Image placeholder","src":"img/theme/team-2.jpg"}})]),_c('a',{staticClass:"avatar avatar-sm rounded-circle",attrs:{"href":"#","data-toggle":"tooltip","data-original-title":"Alexander Smith"}},[_c('img',{attrs:{"alt":"Image placeholder","src":"img/theme/team-3.jpg"}})]),_c('a',{staticClass:"avatar avatar-sm rounded-circle",attrs:{"href":"#","data-toggle":"tooltip","data-original-title":"Jessica Doe"}},[_c('img',{attrs:{"alt":"Image placeholder","src":"img/theme/team-4.jpg"}})])])]),_c('el-table-column',{attrs:{"label":"Completion","prop":"completion","min-width":"240px","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex align-items-center"},[_c('span',{staticClass:"completion mr-2"},[_vm._v(_vm._s(row.completion)+"%")]),_c('div',[_c('base-progress',{attrs:{"type":row.statusType,"value":row.completion}})],1)])]}}])}),_c('el-table-column',{attrs:{"min-width":"180px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-dropdown',{staticClass:"dropdown",attrs:{"trigger":"click"}},[_c('span',{staticClass:"btn btn-sm btn-icon-only text-light"},[_c('i',{staticClass:"fas fa-ellipsis-v mt-2"})]),_c('el-dropdown-menu',{staticClass:"dropdown-menu dropdown-menu-arrow show",attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('b-dropdown-item',[_vm._v("Action")]),_c('b-dropdown-item',[_vm._v("Another action")]),_c('b-dropdown-item',[_vm._v("Something else here")])],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }